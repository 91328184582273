const en = {
  header: {
    home: "首页",
    about: "关于汉图",
    introduction: "公司介绍",
    honors: "荣誉资质",
    awards: "斩获奖项",
    business: "业务体系",
    jiyin: "极印",
    liene: "Liene",
    odm: "技术解决方案",
    contact: "联系我们",
    service: "服务与支持",
  },
  footer: {
    contact: "联系方式",
    phone: "电话",
    email: "邮箱",
    reporting: "违法和不良信息举报：",
    intellectual: "侵权举报",
    security: "沪公网安备 31011502005266",
    icp: "沪ICP备17014357号-1",
  },
  home: {
    full_video: "完整视频",
    learn_more: "了解更多",
    view_offical: "查看官网",
    slogan1: "更年轻 更能打",
    slogan2: "Let inspiration enrich new era",
    slogan3: "一站式全技术打印机设计研发生产制造商",
  },
  introduction: {
    about: "关于我们",
    introduction1:
      "上海汉图科技有限公司于2017年 03月13日注册成立，专注于智能硬件、物联网应用和影像输出产品以及服务的设计、研发、生产与销售，积极推动打印机行业的移动化、网络化和智能化。汉图专注于打印，聚焦所有资源，深耕行业与产品，用产品与用户沟通，用品质为品牌背书，研发出一系列符合消费者打印习惯和打印需求的高质量产品或者服务。",
    introduction2:
      "公司自2019年开始取得营业收入，到2022年实现了超过6亿元销售额。据IDC及汉图内部不完全数据统计，公司照片打印机市场占有率达60%以上，喷墨打印机市场占有率近10%。其中，2019年为小米设计生产的小米米家喷墨打印机，实现了喷墨打印机国产品牌零的突破；2022年为小米设计生产的小米激光打印一体机K200，实现了公司打印全品类覆盖。2024年推出高速激光打印机，搭载第一款国产打印机SoC芯片，100%自有自有知识产权。汉图科技旗下自有品牌极印和Liene也先后推出多款产品，并受到市场广泛认可。",
    introduction3:
      "汉图的主创团队来自惠普、华为、佳能、理光等科技公司，研发团队具备十余年全球打印机项目经验，是唯一具备照片、喷墨与激光自研能力的国产企业。汉图在上海、深圳与苏州组建近200人的研发团队，在北京设立市场与销售团队，在湖南岳阳和浙江湖州建立生产基地，生产打印机设备及耗材。未来汉图将继续钻研技术，借助与用户更近、与行业更近的双重优势，完善打印生态，致力于成为中国打印专家。",
    milestones: "发展历程",
    milestone1: "2017年3月，汉图正式成立",
    milestone2: "2018年2月，汉图第一款产品：极印手机照片打印机正式问世",
    milestone3: "2018年4月，极印手机照片打印机荣获iF Design Award 2018",
    milestone4:
      "2018年7月，极印手机照片打印机荣获Red Dot 德国红点工业设计奖 2018",
    milestone5: "2018年12月，小米米家照片打印机上市登顶成为爆款产品",
    milestone6:
      "2019年9月，首款喷墨打印机，小米米家喷墨打印机上市，小米口袋照片打印机也同步上市",
    milestone7: "2019年12月，极印第二款产品极印照片打印机上市",
    milestone8:
      "2020年3月，薇娅直播间上架不到5分钟，6600台极印留声照片打印机售罄",
    milestone9: "2020年6月，小米米家喷墨一体机上市",
    milestone10:
      "2020年7月，米家打印机系列UI设计界面荣获 Red Dot 德国红点界面设计奖 2020",
    milestone11:
      "2020年10月，小米米家喷墨打印机荣获日本Good Design工业设计奖2019",
    milestone12:
      "2020年10月，极印留声照片打印机荣获DIA 中国设计智造大奖 佳作奖 2020",
    milestone13: "2020年11月，获评上海市高新技术企业",
    milestone14: "2020年12月，小米米家照片打印机1S上市",
    milestone15:
      "2021年7月，小米口袋照片打印机荣获Red Dot 德国红点奖 Winner 2021",
    milestone16:
      "2021年10月，小米口袋照片打印机荣获日本Good Design工业设计奖2020",
    milestone17: "2021年10月，获评质量管理体系认证(ISO90001)",
    milestone18: "2021年12月，获评上海市引领设计示范企业",
    milestone19: "2022年3月，小米米家连供喷墨打印一体机上市",
    milestone20: "2022年5月，获评上海市专精特新企业",
    milestone21: "2022年5月，小米激光打印一体机K200上市",
    milestone22: "2022年9月，极印立可印口袋照片打印机开启预售",
    milestone23: "2023年2月，获评上海市2022年度“科技创新行动计划”科技小巨人",
    milestone24: "2023年2月，小米激光打印一体机K100上市",
    milestone25: "2023年4月，口袋照片打印机Kiwi获得汉诺威工业设计论坛 IF奖",
    milestone26: "2023年7月，获国家级专精特新“小巨人”企业",
    milestone27:
      "2023年9月， 口袋照片打印机Kiwi获2022-2023年度上海设计100+入围作品",
    milestone28: "2023年11月，入选2023年浦东新区文创项目",
    milestone29: "2024年4月，浦东十大文创引领企业。",
    milestone30: "2024年9月，极印高速激光打印机系列上市",
    adress_shanghai: "上海｜研发与企业总部",
    adress_huzhou: "湖州｜生产与运营基地",
    adress_shenzhen: "深圳｜供应链与跨境电商",
    adress_beijing: "北京｜市场与销售",
  },
  product: {
    laser: "激光打印机",
    inkjet: "喷墨打印机",
    photo: "照片打印机",
    haocai: "打印机耗材",
    buy_product: "整机",
    buy_consumables: "耗材",
    fennel: "极印留声照片打印机",
    kiwi: "极印口袋照片打印机",
    T200: "极印连供喷墨打印一体机",
    XP356DNL: "极印黑白激光打印机 XP356DNL",
    XA356DNL: "极印黑白激光一体机XA356DNL",
    XP406DNL: "极印黑白激光打印机 XP406DNL",
    XA406DNL: "极印黑白激光一体机 XA406DNL",
    XP356DNDW: "极印黑白激光打印机 XP356DN/DW",
    XM356DNDW: "极印黑白激光一体机 XM356DN/DW",
    XA356DNDW: "极印黑白激光一体机 XA356DN/DW",
    XP406DNDW: "极印黑白激光打印机 XP406DN/DW",
    XA406DNDW: "极印黑白激光一体机 XA406DN/DW",
    LK156D: "极印激光打印机硒鼓 LK156D",
    LK156T: "极印激光打印机墨粉 LK156T",
    LK306T: "极印激光打印机墨粉 LK306T",
    LK606T: "极印激光打印机墨粉  LK606T",
    LK156DL: "极印激光打印机硒鼓 LK156DL",
    LK306TL: "极印激光打印机墨粉 LK306TL",
    LK606TL: "极印激光打印机墨粉 LK606TL",
    LK156T_group: "极印激光打印机墨粉 LK156T/LK306T",
    LK306TL_group: "极印激光打印机墨粉 LK306TL/LK606TL",
    anise: "小米米家喷墨打印机",
    rmy: "小米米家连供喷墨打印一体机",
    mint: "小米口袋照片打印机1S",
    basil: "小米口袋照片打印机",
    honey1s: "小米米家照片打印机1S",
    lager: "Xiaomi激光打印一体机K200",
    ginger: "小米米家喷墨打印一体机",
    lambic: "Xiaomi激光打印机K100",
    fuji: "富士数码照片打印机Ⅱ",
    product_details: "产品详情",
    functional: "功能参数",
    consumables: "适配耗材",
    models: "适配机型",
    ink_cyan: "极印T20墨水 青色(Cyan)",
    ink_magenta: "极印T20墨水 洋红色(Magenta)",
    ink_yellow: "极印T20墨水 黄色(Yellow)",
    ink_black: "极印T20墨水 黑色(Black)",
  },
  contact: {
    label: "选择您喜欢的方式，与我们联系",
    business: "商务合作",
    business_detail:
      "汉图拥有全系列打印技术：照片、喷墨、激光的智能打印服务解决方案。目前，汉图旗下拥有极印、Liene2个品牌的产品，技术服务品牌有米家、Xiaomi的打印机，如果您有采购、招商与其他形式合作的需求，请与我们取得联系。",
    brand: "品牌合作",
    brand_detail:
      "极印品牌专注国内，Liene品牌专注海外，这2个汉图自有品牌通过简洁、时尚的现代设计语言，以及智能化的功能设计，深受年轻人的喜爱。如果您的品牌受众亦为年轻、追求生活品质的群体，我们期待与您进行合作，实现双赢。",
    cantact: "联系汉图",
    cantact_detail:
      "如果您有其他想要咨询的内容，或想要对我们的产品与服务提出建议，请通过邮件与我们取得联系。",
  },
};

export default en;
