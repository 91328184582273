const en = {
  header: {
    home: "Home",
    about: "About Hannto",
    introduction: "Company Introduction",
    honors: "Honors & Qualifications",
    awards: "Awards & Achievements",
    business: "Business System",
    jiyin: "Jiyin",
    liene: "Liene",
    odm: "Technical solutions",
    contact: "Contact Us",
    service: "Service & Support",
  },
  footer: {
    contact: "Contact Information",
    phone: "Phone",
    email: "Email",
    reporting: "Reporting Illegal and Inappropriate Content: ",
    intellectual: "Intellectual Property Rights Infringement Report",
    security: "Shanghai Public Network Security No. 31011502005266",
    icp: "Shanghai ICP No. 17014357-1",
  },
  home: {
    full_video: "Full Video",
    learn_more: "Learn More",
    view_offical: "View Website",
    slogan1: "More Than Faster",
    slogan2: "Let inspiration enrich new era",
    slogan3: "One-Stop Full-Technology Printer Design, R&D, and Manufacturing",
  },
  introduction: {
    about: "About Us",
    introduction1:
      "Shanghai Hannto Technology Co., Ltd. was established on March 13, 2017, focusing on the design, development, production, and sales of intelligent hardware, Internet of Things applications, and imaging output products and services. It actively promotes the mobilization, networking, and intelligence of the printer industry. Hannto is dedicated to printing, focusing all its resources on deeply cultivating the industry and products, communicating with users through products, endorsing the brand with quality, and developing a series of high-quality products or services that meet consumers' printing habits and needs.",
    introduction2:
      "Since starting to achieve business income in 2019, the company has realized a sales volume of 1 million USD by 2022. According to incomplete statistics from IDC and Hannto internally, the company has achieved over 60% market share of China's photo printer market; nearly 10% market share in China's inkjet printer market, among which, the Mi Wireless Inkjet Printer designed and produced for Xiaomi in 2019, achieved a breakthrough of zero in the domestic brand of inkjet printers; in 2022, the Mi All-in-One Laser Printer K200 designed and produced for Xiaomi, achieved full category coverage in printing. In 2024, a high-speed laser printer equipped with the first domestically produced printer SoC chip, boasting 100% independent intellectual property rights, was launched. Hannto Technology's own brands (Jiyin and Liene) have also launched a number of products and have been widely recognized by the market.",
    introduction3:
      "Hannto's founding team comes from technology companies such as HP, Huawei, Canon, and Ricoh. The R&D team has more than ten years of global experience in printer projects and is the only domestic enterprise with self-research capabilities in photo, inkjet, and laser printing. Hannto has assembled a nearly 200-person R&D team in Shanghai, Shenzhen, and Suzhou, established a marketing and sales team in Beijing, and put into production related equipment and consumables in Yueyng and Huzhou. In the future, Hannto will continue to delve into technology, leveraging the dual advantages of being closer to users and the industry, to perfect the printing ecosystem, committed to becoming a printing expert in China.",
    milestones: "Corporate Milestones",
    milestone1: "March 2017: Hannto was officially established.",
    milestone2:
      "February 2018: The first Hannto product, the Jiyin mobile photo printer, was officially launched.",
    milestone3:
      "April 2018: Jiyin mobile photo printer won the iF Design Award 2018",
    milestone4:
      "July 2018: Jiyin mobile photo printer won the Red Dot German Industrial Design Award 2018.",
    milestone5:
      "December 2018: Xiaomi Mijia photo printer was launched and became a blockbuster product.",
    milestone6:
      "September 2019: The first inkjet printer, Mi Wireless Inkjet Printer, was launched, along with the Xiaomi pocket photo printer.",
    milestone7:
      "December 2019: The second Jiyin product, theJiyin photo printer, was launched.",
    milestone8:
      "March 2020: 6,600 Jiyin Sound Photo Printers sold out in less than 5 minutes in Via's live broadcast room.",
    milestone9:
      "June 2020: Xiaomi Mijia inkjet all-in-one printer was launched.",
    milestone10:
      "July 2020: Mijia printer series UI design interface won the Red Dot German Red Dot Interface Design Award 2020.",
    milestone11:
      "October 2020: Mi Wireless Inkjet Printer won the Japan Good Design Industrial Design Award 2019.",
    milestone12:
      "October 2020: Jiyin Sound Photo Printer won the DIA China Design Intelligence Award 2020.",
    milestone13:
      "November 2020: Recognized as a Shanghai High-Tech Enterprise.",
    milestone14: "December 2020: Mi Wireless Photo Printer 1S was launched.",
    milestone15:
      "July 2021: Xiaomi pocket photo printer won the Red Dot German Red Dot Award Winner 2021.",
    milestone16:
      "October 2021: Xiaomi pocket photo printer won the Japan Good Design Industrial Design Award 2020.",
    milestone17:
      "October 2021: Received Quality Management System Certification (ISO90001).",
    milestone18:
      "Recognized as a Shanghai Leading Design Demonstration Enterprise.",
    milestone19:
      "March 2022: Mi Wireless All-in-One Inkjet Printer  was launched.",
    milestone20:
      "May 2022: Recognized as a Shanghai Specialized, Fined, Peculiar, and New Enterprise.",
    milestone21: "May 2022: Mi All-in-One Laser Printer K200 was launched.",
    milestone22:
      "September 2022: Jiyin Lickable Pocket Photo Printer pre-sale started.",
    milestone23:
      'February 2023: Recognized as a Shanghai 2022 "Science and Technology Innovation Action Plan" Science and Technology Little Giant.',
    milestone24:
      "February 2023: Xiaomi laser all-in-one printer K100 was launched.",
    milestone25:
      "April 2023: Pocket photo printer Kiwi won the Hannover Industrial Design Forum IF Award.",
    milestone26:
      'July 2023: Recognized as a National-Level Specialized, Fined, Peculiar, and New "Little Giant" Enterprise.',
    milestone27:
      "September 2023: Pocket photo printer Kiwi was selected as a finalist for the 2022-2023 Shanghai Design 100+.",
    milestone28:
      "November 2023: Selected for the 2023 Pudong New Area Cultural and Creative Project.",
    milestone30:
      "September 2024: Jiyin High Speed Laser Printer Series launched",
    milestone29: "Top 10 Cultural and Creative Leading Enterprises in Pudong",
    adress_shanghai: "Shanghai｜R&D and Corporate Headquarter",
    adress_huzhou: "Huzhou｜Production and Operations Base",
    adress_shenzhen: "Shenzhen｜Supply Chain and Cross-border E-commerce",
    adress_beijing: "Beijing｜Marketing and Sales",
  },
  product: {
    laser: "Laser series",
    inkjet: "Inkjet series",
    photo: "Photo series",
    haocai: "Supplies",
    buy_product: "Product",
    buy_consumables: "Consumables",
    fennel: "Jiyin Sound Photo Printer",
    kiwi: "Jiyin Pocket Photo Printer",
    T200: "Jiyin Continuous Supply Inkjet All-in-One Printer",
    XP356DNL: "Jiyin Mono Laser Printer XP356DNL",
    XA356DNL: "Jiyin Mono Laser MFP Printer XA356DNL",
    XP406DNL: "Jiyin Mono Laser Printer XP406DNL",
    XA406DNL: "Jiyin Mono Laser MFP Printer XA406DNL",
    XP356DNDW: "Jiyin Mono Laser Printer XP356DN/DW",
    XM356DNDW: "Jiyin Mono Laser MFP Printer XM356DN/DW",
    XA356DNDW: "Jiyin Mono Laser MFP Printer XA356DN/DW",
    XP406DNDW: "Jiyin Mono Laser Printer XP406DN/DW",
    XA406DNDW: "Jiyin Mono Laser MFP Printer XA406DN/DW",
    LK156D: "Jiyin Laser Printer Drum LK156D",
    LK156T: "Jiyin Laser Printer Toner Cartridge LK156T",
    LK306T: "Jiyin Laser Printer Toner Cartridge LK306T",
    LK606T: "Jiyin Laser Printer Toner Cartridge LK606T",
    LK156DL: "Jiyin Laser Printer Toner Cartridge LK156DL",
    LK306TL: "Jiyin Laser Printer Toner Cartridge LK306TL",
    LK606TL: "Jiyin Laser Printer Toner Cartridge LK606TL",
    LK156T_group: "Jiyin Laser Printer Toner Cartridge LK156T/LK306T",
    LK306TL_group: "Jiyin Laser Printer Toner Cartridge LK306TL/LK606TL",
    anise: "Mi Wireless Inkjet Printer",
    rmy: "Mi Wireless All-in-One Inkjet Printer",
    mint: "Mi Portable Photo Printer 1S",
    basil: "Mi Portable Photo Printer",
    honey1s: "Mi Wireless Photo Printer 1S",
    lager: "Mi All-in-One Laser Printer K200",
    ginger: "Mi All-in-One Inkjet Printer",
    lambic: "Mi Laser Printer K100",
    fuji: "FUJIFILM photo printer Ⅱ",
    product_details: "Product Details",
    functional: "Functional Parameters",
    consumables: "Compatible Consumables",
    models: "Compatible Models",
    ink_cyan: "Jiyin T20 Ink Refill Cyan",
    ink_magenta: "Jiyin T20 Ink Refill Magenta",
    ink_yellow: "Jiyin T20 Ink Refill Yellow",
    ink_black: "Jiyin T20 Ink Refill Black",
  },
  contact: {
    label: "Choose your preferred way to contact us",
    business: "Business Cooperation",
    business_detail:
      "Hannto possesses a full range of printing technologies including photo, inkjet, and laser smart printing service solutions. Currently, under the Hannto umbrella, there are products from two brands: Jiyin and Liene, along with technical service brands such as Mijia and Xiaomi printers. If you have needs for procurement, franchising, or other forms of cooperation, please contact us.",
    brand: "Brand Cooperation",
    brand_detail:
      "The Jiyin brand is focused on the domestic market, while the Liene brand targets overseas markets. These two Hannto-owned brands are beloved by young people for their simple, stylish modern design language, and smart functionality. If your brand's audience is also young and pursues quality of life, we look forward to cooperating with you to achieve a win-win situation.",
    cantact: "Contact Hannto",
    cantact_detail:
      "If you have other inquiries or would like to offer suggestions about our products and services, please contact us via email.",
  },
};

export default en;
