<template>
  <div>
    <footer class="footer-mobile">
      <div class="logo-wrap" @click="go('/home')">
        <img src="../assets/hannto-small.svg" alt="hannto" />
      </div>
      <ul class="menu-list">
        <li class="menu-item" @click="go('/about/introduction')">
          <span class="menu-text">{{ $t("header.about") }}</span>
          <img class="menu-icon" src="../assets/right.svg" alt="right" />
        </li>
        <li class="menu-item" @click="go('/business/jiyin')">
          <span class="menu-text">{{ $t("header.business") }}</span>
          <img class="menu-icon" src="../assets/right.svg" alt="right" />
        </li>
        <li class="menu-item" @click="go('/contact')">
          <span class="menu-text">{{ $t("header.contact") }}</span>
          <img class="menu-icon" src="../assets/right.svg" alt="right" />
        </li>
        <li class="menu-item" @click="go('/service')">
          <span class="menu-text">{{ $t("header.service") }}</span>
          <img class="menu-icon" src="../assets/right.svg" alt="right" />
        </li>
      </ul>
      <div class="gap-line"></div>
      <div class="icp-info">
        <a href="https://beian.miit.gov.cn/">{{ $t("footer.icp") }}</a>
        <a href="https://beian.miit.gov.cn/">{{ $t("footer.security") }}</a>
        <a href="mailto:privacy@hannto.com"
          >{{ $t("footer.intellectual") }} /
          {{ $t("footer.reporting") }}privacy@hannto.com</a
        >
      </div>
    </footer>
    <footer class="footer-pc">
      <div class="footer-body">
        <div class="logo-wrap">
          <img
            @click="go('/home')"
            class="hannto-logo"
            src="../assets/hannto-small.svg"
            alt="hannto"
          />
        </div>
        <div class="footer-menu">
          <ul>
            <li class="menu-root hover-link">
              <router-link class="bolder-text" to="/about/introduction">{{
                $t("header.about")
              }}</router-link>
            </li>
            <li class="menu-child hover-link">
              <router-link class="normal-text" to="/about/introduction">{{
                $t("header.introduction")
              }}</router-link>
            </li>
            <li class="menu-child hover-link">
              <router-link class="normal-text" to="/about/honors">{{
                $t("header.honors")
              }}</router-link>
            </li>
            <li class="menu-child hover-link">
              <router-link class="normal-text" to="/about/awards">{{
                $t("header.awards")
              }}</router-link>
            </li>
          </ul>
          <ul>
            <li class="menu-root hover-link">
              <router-link class="bolder-text" to="/business/jiyin">{{
                $t("header.business")
              }}</router-link>
            </li>
            <li class="menu-child hover-link">
              <router-link class="normal-text" to="/business/jiyin">{{
                $t("header.jiyin")
              }}</router-link>
            </li>
            <li class="menu-child hover-link">
              <router-link class="normal-text" to="/business/liene">{{
                $t("header.liene")
              }}</router-link>
            </li>
            <li class="menu-child hover-link">
              <router-link class="normal-text" to="/business/odm">{{
                $t("header.odm")
              }}</router-link>
            </li>
          </ul>
          <ul>
            <li class="menu-root hover-link">
              <router-link class="bolder-text" to="/contact">{{
                $t("header.contact")
              }}</router-link>
            </li>
            <li class="menu-child normal-text" style="cursor: auto">
              021-50561577
            </li>
            <li class="menu-child normal-text" style="cursor: auto">
              4000888253
            </li>
            <li class="menu-child normal-text" style="cursor: auto">
              business@hannto.com
            </li>
          </ul>
          <ul>
            <li class="menu-root hover-link">
              <router-link class="bolder-text" to="/service">{{
                $t("header.service")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-icp">
          <a
            class="hover-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://beian.miit.gov.cn/"
            >{{ $t("footer.icp") }}</a
          >
          <a
            class="hover-link"
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502005266"
            >{{ $t("footer.security") }}</a
          >
          <a class="hover-link" href="mailto:privacy@hannto.com"
            >{{ $t("footer.intellectual") }} /
            {{ $t("footer.reporting") }}privacy@hannto.com</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  methods: {
    go(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="less" scoped>
.footer-pc {
  padding-bottom: 29px;
  background-color: #f5f5f7;
  .footer-body {
    width: 932px;
    margin: 0 auto;
    .hover-link:hover {
      opacity: 0.6;
      text-decoration: underline;
    }
    .logo-wrap {
      margin-bottom: 23px;
      padding: 15px 0 18px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .hannto-logo {
        width: 67px;
        height: 11px;
        display: block;
        cursor: pointer;
      }
    }
    .footer-menu {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      padding-bottom: 38px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .normal-text {
        display: block;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.6);
      }
      .bolder-text {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #0a0a0a;
        line-height: 18px;
      }
      .menu-root {
        cursor: pointer;
        margin-bottom: 10px;
      }
      .menu-child {
        cursor: pointer;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .footer-icp {
      display: flex;
      flex-wrap: wrap;
      a {
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        color: #0a0a0a;
        margin-right: 50px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.footer-mobile {
  display: none;
  padding: 26px 15px;
  background-color: #f5f5f7;
  .logo-wrap {
    width: 98px;
    margin: 0 auto 40px;
    img {
      width: 100%;
      display: block;
    }
  }
  .menu-list {
    .menu-item {
      height: 20px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        margin-bottom: 0;
      }
      &:active {
        opacity: 0.6;
      }
      .menu-text {
        font-size: 14px;
        line-height: 20px;
      }
      .menu-icon {
        width: 16px;
      }
    }
  }
  .gap-line {
    height: 1px;
    margin: 20px 0;
    background-color: #e6e6e6;
  }
  .icp-info {
    display: flex;
    flex-wrap: wrap;
    a {
      font-size: 12px;
      color: #0a0a0a;
      margin-right: 16px;
      line-height: 22px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .footer-pc {
    display: none;
  }
  .footer-mobile {
    display: block;
  }
}
</style>
