<template>
  <div id="app" class="my-app">
    <Header></Header>
    <div class="my-content">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>
