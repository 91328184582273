<template>
  <div>
    <header class="header-mobile">
      <div v-if="!menuVisible" class="logo-wrap" @click="go('/home')">
        <img src="../assets/hannto.svg" alt="hannto" />
      </div>
      <div v-if="!menuVisible" class="menu-icon" @click="menuVisible = true">
        <img src="../assets/menu.svg" alt="menu" />
      </div>
      <div
        :class="menuVisible ? 'menu-container menu-visible' : 'menu-container'"
      >
        <div class="menu-list-header">
          <div class="menu-close-icon" @click="menuVisible = false">
            <img src="../assets/close.svg" alt="close" />
          </div>
        </div>
        <ul class="menu-list">
          <li class="menu-item">
            <router-link
              class="normal-text"
              active-class="menu-item-active"
              to="/home"
              @click.native="menuVisible = false"
              >{{ $t("header.home") }}</router-link
            >
          </li>
          <li class="menu-item">
            <router-link
              class="normal-text"
              active-class="menu-item-active"
              to="/about/introduction"
              @click.native="menuVisible = false"
              >{{ $t("header.introduction") }}</router-link
            >
          </li>
          <li class="menu-item">
            <router-link
              class="normal-text"
              active-class="menu-item-active"
              to="/about/honors"
              @click.native="menuVisible = false"
              >{{ $t("header.honors") }}</router-link
            >
          </li>
          <li class="menu-item">
            <router-link
              class="normal-text"
              active-class="menu-item-active"
              to="/about/awards"
              @click.native="menuVisible = false"
              >{{ $t("header.awards") }}</router-link
            >
          </li>
          <li class="menu-item">
            <router-link
              class="normal-text"
              active-class="menu-item-active"
              to="/business/jiyin"
              @click.native="menuVisible = false"
              >{{ $t("header.jiyin") }}</router-link
            >
          </li>
          <li class="menu-item">
            <router-link
              class="normal-text"
              active-class="menu-item-active"
              to="/business/liene"
              @click.native="menuVisible = false"
              >{{ $t("header.liene") }}</router-link
            >
          </li>
          <li class="menu-item">
            <router-link
              class="normal-text"
              active-class="menu-item-active"
              to="/business/odm"
              @click.native="menuVisible = false"
              >{{ $t("header.odm") }}</router-link
            >
          </li>
          <li class="menu-item">
            <router-link
              class="normal-text"
              active-class="menu-item-active"
              to="/contact"
              @click.native="menuVisible = false"
              >{{ $t("header.contact") }}</router-link
            >
          </li>
          <li class="menu-item">
            <router-link
              class="normal-text"
              active-class="menu-item-active"
              to="/service"
              @click.native="menuVisible = false"
              >{{ $t("header.service") }}</router-link
            >
          </li>
          <li class="menu-item">
            <p
              class="normal-text"
              style="display: inline-block"
              @click="changeLanguage()"
            >
              <span :class="locale === 'zh' ? 'menu-item-active' : ''">中</span>
              /
              <span :class="locale === 'en' ? 'menu-item-active' : ''">EN</span>
            </p>
          </li>
        </ul>
      </div>
    </header>
    <header class="header-pc">
      <div class="header-content main-container">
        <div class="logo-wrap" @click="go('/home')">
          <img class="hannto-logo" src="../assets/hannto.svg" alt="hannto" />
        </div>
        <ul
          class="menu-list"
          :class="locale === 'zh' ? 'menu-w-zh' : 'menu-w-en'"
        >
          <li class="menu-item">
            <router-link class="normal-text" to="/home">{{
              $t("header.home")
            }}</router-link>
            <div class="menu-hover-bar"></div>
          </li>
          <li class="menu-item" ref="menuItem1">
            <router-link class="normal-text" to="/about/introduction">{{
              $t("header.about")
            }}</router-link>
            <div class="menu-hover-bar"></div>
            <div class="menu-sub-mask"></div>
            <div class="menu-sub-wrap" @click="removeHover(1)">
              <router-link class="sub-menu" to="/about/introduction">{{
                $t("header.introduction")
              }}</router-link>
              <router-link class="sub-menu" to="/about/honors">{{
                $t("header.honors")
              }}</router-link>
              <router-link class="sub-menu" to="/about/awards">{{
                $t("header.awards")
              }}</router-link>
            </div>
          </li>
          <li class="menu-item" ref="menuItem2">
            <router-link class="normal-text" to="/business/jiyin">{{
              $t("header.business")
            }}</router-link>
            <div class="menu-hover-bar"></div>
            <div class="menu-sub-mask"></div>
            <div class="menu-sub-wrap" @click="removeHover(2)">
              <router-link class="sub-menu" to="/business/jiyin">{{
                $t("header.jiyin")
              }}</router-link>
              <router-link class="sub-menu" to="/business/liene">{{
                $t("header.liene")
              }}</router-link>
              <router-link class="sub-menu" to="/business/odm">{{
                $t("header.odm")
              }}</router-link>
            </div>
          </li>
          <li class="menu-item">
            <router-link class="normal-text" to="/contact">{{
              $t("header.contact")
            }}</router-link>
            <div class="menu-hover-bar"></div>
          </li>
          <li class="menu-item">
            <router-link class="normal-text" to="/service">{{
              $t("header.service")
            }}</router-link>
            <div class="menu-hover-bar"></div>
          </li>
        </ul>
        <div class="language-wrap" @click="changeLanguage()">
          <img class="earth-icon" src="../assets/earth.svg" alt="" />
          <span
            class="normal-text"
            :class="locale === 'en' ? 'disabled-text' : ''"
            >中</span
          >
          <span class="normal-text">/</span>
          <span
            class="normal-text"
            :class="locale === 'zh' ? 'disabled-text' : ''"
            >EN</span
          >
        </div>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuVisible: false,
      locale: localStorage.getItem("locale") || "zh",
    };
  },
  methods: {
    changeLanguage() {
      localStorage.setItem("locale", this.locale === "zh" ? "en" : "zh");
      location.reload();
    },
    go(path) {
      this.$router.push(path);
    },
    removeHover(type) {
      if (type === 1) {
        this.$refs.menuItem1.style.pointerEvents = "none";
        setTimeout(() => {
          this.$refs.menuItem1.style.pointerEvents = "auto";
        }, 200);
      } else {
        this.$refs.menuItem2.style.pointerEvents = "none";
        setTimeout(() => {
          this.$refs.menuItem2.style.pointerEvents = "auto";
        }, 200);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header-pc {
  width: 100%;
  height: 54px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.9);
  .header-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .normal-text {
      display: block;
      font-size: 14px;
      line-height: 54px;
      font-weight: 500;
      color: rgb(207, 207, 207);
    }

    .disabled-text {
      color: rgba(207, 207, 207, 0.4);
    }
    .logo-wrap {
      width: 132px;
      height: 32px;
      cursor: pointer;
      .hannto-logo {
        width: 132px;
        height: 32px;
        display: block;
      }
    }
    .menu-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.menu-w-zh {
        width: 57.227%;
      }
      &.menu-w-en {
        width: 63.515%;
      }
      .menu-item {
        cursor: pointer;
        position: relative;
        &:hover {
          .menu-hover-bar {
            display: block;
          }
          .menu-sub-mask,
          .menu-sub-wrap {
            max-height: 100px;
          }
        }
        .menu-hover-bar {
          width: 100%;
          height: 2px;
          display: none;
          position: absolute;
          left: 0;
          bottom: 21px;
          background-color: rgba(255, 255, 255, 0.5);
        }
        .menu-sub-mask {
          width: 100vw;
          height: 96px;
          max-height: 0;
          overflow: hidden;
          position: fixed;
          top: 54px;
          left: 0;
          transition: max-height 0.2s;
          background-color: rgba(0, 0, 0, 0.8);
        }
        .menu-sub-wrap {
          max-height: 0;
          overflow: hidden;
          position: absolute;
          top: 54px;
          left: 0;
          transition: max-height 0.2s;
          padding-top: 4px;
          .sub-menu {
            display: block;
            font-size: 12px;
            line-height: 24px;
            white-space: nowrap;
            color: rgb(207, 207, 207);
            &:hover {
              color: rgba(207, 207, 207, 0.6);
            }
          }
        }
      }
    }
    .language-wrap {
      display: flex;
      align-items: center;
      cursor: pointer;
      .earth-icon {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
    }
  }
}
.header-mobile {
  display: none;
  width: 100%;
  height: 60px;
  padding-left: 16px;
  padding-right: 21px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  .logo-wrap {
    width: 80px;
    &:active {
      opacity: 0.6;
    }
    img {
      width: 100%;
      display: block;
    }
  }
  .menu-icon {
    width: 24px;
    &:active {
      opacity: 0.6;
    }
    img {
      width: 100%;
      display: block;
    }
  }
  .menu-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    transition: transform 0.3s;
    transform: translateY(-100vh);
    background-color: rgba(0, 0, 0, 0.95);
    &.menu-visible {
      transform: translateY(0);
    }
    .menu-list-header {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 21px;
      .menu-close-icon {
        width: 36px;
        &:active {
          opacity: 0.6;
        }
        img {
          width: 100%;
          display: block;
        }
      }
    }
    .menu-list {
      padding-top: 21px;
      .menu-item {
        text-align: center;
        margin-bottom: 36px;
        &:last-child {
          margin-bottom: 0;
        }
        .normal-text {
          font-size: 16px;
          color: #ffffff;
          line-height: 22px;
        }
        .menu-item-active {
          font-weight: 600;
          color: #ee5729;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .header-pc {
    display: none;
  }
  .header-mobile {
    display: flex;
  }
}
</style>
