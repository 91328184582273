import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const Home = () => import("@/views/home");
const Awards = () => import("@/views/about/awards");
const Honors = () => import("@/views/about/honors");
const Introduction = () => import("@/views/about/introduction");
const Jiyin = () => import("@/views/business/jiyin");
const Liene = () => import("@/views/business/liene");
const ODM = () => import("@/views/business/odm");
const ProductDetail = () => import("@/views/business/product-detail");
const Contact = () => import("@/views/contact");
const Service = () => import("@/views/service");

export default new Router({
  mode: "history",
  routes: [
    {
      path: "",
      redirect: "/home",
    },
    {
      path: "/home",
      component: Home,
    },
    {
      path: "/about",
      redirect: "/about/introduction",
    },
    {
      path: "/about/awards",
      component: Awards,
    },
    {
      path: "/about/honors",
      component: Honors,
    },
    {
      path: "/about/introduction",
      component: Introduction,
    },
    {
      path: "/business",
      redirect: "/business/jiyin",
    },
    {
      path: "/business/jiyin",
      component: Jiyin,
    },
    {
      path: "/business/liene",
      component: Liene,
    },
    {
      path: "/business/odm",
      component: ODM,
    },
    {
      path: "/business/product-detail",
      component: ProductDetail,
    },
    {
      path: "/contact",
      component: Contact,
    },
    {
      path: "/service",
      component: Service,
    },
    {
      path: "*",
      redirect: "/home",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
